<template>
  <b-card-code

      no-body
  >

    <!-- table -->
    <b-table v-if="items"
             ref="selectableTable"
             :fields="fields"
             :items="items"
             responsive
             class="mb-0"

    >
      <template #cell(prix)="data">
        {{ data.item.prix.toLocaleString('en') }} Dhs
      </template>
      <template #cell(vendeur)="data">
        BC ° : <span class="font-weight-bolder">#{{data.item.id}}</span> <br/>
        Vendeur : <span class="font-weight-bolder">{{ data.item.vendeur.name }}</span> <br/>
        Client : <span class="font-weight-bolder">{{ data.item.offre.client.full_name }}</span>
      </template>
      <template #cell(ventes)="data">
        <div v-if="index <= 1" v-for="(item, index) in data.item.offre.ventes" class="vehicule-item">
          <vehicule-overview :with-image="false" :vehicule="item.vehicule" />

        </div>
        <span v-if="data.item.offre.ventes.length > 2">... + {{data.item.offre.ventes.length - 2}} véhicules </span>
      </template>
      <template #cell(reprises)="data">
        <div v-if="index <= 1" v-for="(item, index) in data.item.offre.reprises" class="vehicule-item">
          <vehicule-overview :with-image="false" :vehicule="item.vehicule" />

        </div>
        <span v-if="data.item.offre.reprises.length > 2">... + {{data.item.offre.reprises.length - 2}} véhicules </span>
      </template>
    </b-table>


  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BTable, BButton, BFormGroup, BAvatar, BBadge, BCardBody,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import VehiculeOverview from "@/components/Vehicule/VehiculeOverview";


export default {
  components: {
    VehiculeOverview,
    BCardCode,
    BTable,
    BButton,
    BFormGroup,
    BAvatar,
    BCardBody,
    BBadge,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      items: [],
      fields: [{
        key: 'vendeur', label: ''
      }, 'ventes', 'reprises', 'prix']
    }
  },
  created() {
    let _this = this;
    this.$store.dispatch('listBcModule/fetchCommandes').then(function (response) {
      _this.items = response.data;
    })
  },
  methods: {},
}
</script>

<style lang="scss">
.b-table-selectable {
  .feather {
    font-size: 1.3rem;
  }
}

</style>
