import { render, staticRenderFns } from "./ListeBCPage.vue?vue&type=template&id=6308b330&"
import script from "./ListeBCPage.vue?vue&type=script&lang=js&"
export * from "./ListeBCPage.vue?vue&type=script&lang=js&"
import style0 from "./ListeBCPage.vue?vue&type=style&index=0&id=6308b330&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports